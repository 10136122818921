/* eslint-disable */
//import "@babel/polyfill";
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// showWeekNumbers firstDay getStartDate getEndDate
import { login, logout, forgotPassword, create } from './login';
import { updateSettings, resetPassword } from './updateSettings';
import {
  docReady,
  initiateCard,
  search,
  litePicker,
  litePickerInit,
  updateView,
  toggleSpinner,
  showAlert,
  initiateObserver,
  submitEvent,
  syncMyfoneUsers,
  toggle,
  saveSmses,
  addAnswer,
} from './utils';
import { createSms, updateSms } from './controllers';

// DOM ELEMENTS
const loginForm = document.querySelector('.form--login');
const messages = document.querySelectorAll('#messages span');
const createForm = document.querySelector('.form--create');
const logOutBtn = document.querySelector('.nav__el--logout');
const cards = document.querySelectorAll('.card--wrapper');
const datePicker = document.getElementById('datepicker'); //- Datepicker https://wakirin.github.io/Litepicker/
const phoneNumber = document.getElementById('phone-number');
const localNumber = document.getElementById('local-number');
const searchForm = document.querySelector('.form--search');
const sortElements = document.querySelectorAll(
  '.header--sort .card__data:not(.filler)',
);
const pageCount = document.getElementById('page');
const updateDataForm = document.querySelector('.form-user-data');
const smsSettingsForm = document.querySelector('.form-user-sms-settings');
const smsEditForm = document.querySelector('.form-sms-edit');
const dentalsuite = document.querySelectorAll('.btn--dentalsuite-save');

const syncHubspotUsersBtn = document.querySelector('.btn--new-hubspot-users');
const syncHubspotBtn = document.querySelector('.btn--hubspot-sync');
const syncMyfoneUsersBtn = document.querySelector('.btn--new-myfone-users');
const salesforceInstallBtn = document.querySelector('.btn--install-salesforce');
const salesforceUninstallBtn = document.querySelector(
  '.btn--uninstall-salesforce',
);
const hubspotInstallBtn = document.querySelector('.btn--install-hubspot');
const hubspotUninstallBtn = document.querySelector('.btn--hubspot-uninstall');
const updateCompanyDataForm = document.querySelector('.form-company-data');
const userPasswordForm = document.querySelector('.form-user-password');
const resetPasswordForm = document.querySelector('.form-user-password--reset');
const forgotPasswordForm = document.querySelector(
  '.form-user-password--forgot',
);

// VALUES

// DELEGATION
if (cards) {
  Array.from(cards).forEach((card) => {
    if (document.querySelector('.users--hubspot')) {
      card = card.querySelector('.card');
    }
    docReady(initiateCard(card));
  });
  if (datePicker) {
    litePickerInit(datePicker, phoneNumber, localNumber, searchForm);
  }
}

if (loginForm) {
  loginForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    await login(email, password);
  });
}

if (messages) {
  Array.from(messages).forEach((element) => {
    showAlert(element.dataset.type, element.dataset.msg);
  });
}

if (dentalsuite) {
  Array.from(dentalsuite).forEach((dentalsuiteBtn) => {
    // get data-department from form
    const department = dentalsuiteBtn.dataset.department;
    // keep only relevant options in phone select, based on localnumber select
    document
      .getElementById(`localnumber-${department}`)
      .addEventListener('change', function () {
        const phoneSelect = document.getElementById(`phone-${department}`);
        const options = phoneSelect.getElementsByTagName('option');
        for (let i = 0; i < options.length; i++) {
          if (options[i].getAttribute('data-localnumber') != this.value) {
            options[i].style.display = 'none';
            // if options[i] is selected, deselect it
            if (options[i].selected) {
              options[i].selected = false;
            }
          } else {
            options[i].style.display = 'block';
            if (options[i].getAttribute('value') == '0000') {
              // disable 0000 option
              options[i].disabled = true;
            }
          }
        }
      });
    dentalsuiteBtn.addEventListener('click', async (e) => {
      e.preventDefault();
      // safe clinic input fields
      updateSettings(
        {
          clinic: {
            department,
            ipAddress: document.getElementById(`ipaddress-${department}`).value,
            localNumber: document.getElementById(`localnumber-${department}`)
              .value,
            phone: document.getElementById(`phone-${department}`).value,
            port: document.getElementById(`port-${department}`).value,
            username: document.getElementById(`username-${department}`).value,
            password: document.getElementById(`password-${department}`).value,
          },
        },
        'firmadata',
      );
    });
  });
}

if (searchForm) {
  searchForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggleSpinner('on');
    const phoneNumberValue = document.getElementById('phone-number').value;
    const localNumberValue = document.getElementById('local-number').value;
    const page = document.getElementById('page').value;
    const startDate = litePicker.getStartDate();
    const endDate = litePicker.getEndDate();
    console.log(
      'phoneNumberValue',
      phoneNumberValue,
      'localNumberValue',
      localNumberValue,
      'startDate',
      startDate,
      'endDate',
      endDate,
      'page',
      page,
    );
    const recordings = await search(
      phoneNumberValue,
      localNumberValue,
      startDate,
      endDate,
      page,
    );
    updateView('recordings', recordings);
    toggleSpinner('off');
  });
}

// if pageCount is on the page, we need to detect when we reach the bottom
// and then load the next batch of recordings
if (pageCount) {
  initiateObserver(document.getElementById('recordings-footer'), searchForm);
}

if (sortElements) {
  Array.from(sortElements).forEach((element) => {
    element.addEventListener('click', (e) => {
      // remove all active and add active to as this if it's not allready
      // if it is, remove dash if it has it, else remove active
      e.preventDefault();
      let dSet = element.dataset.sort;
      let dash = '-';
      if (element.classList.contains('active')) {
        if (dSet.startsWith(dash)) {
          dSet = dSet.substr(1);
          dash = '';
        }
        if (element.classList.contains('asc')) {
          element.classList.remove('asc');
        } else {
          element.classList.add('asc');
        }
      } else {
        const allElements = document.querySelectorAll(
          '.header--sort .card__data.active',
        );
        Array.from(allElements).forEach((el) => {
          el.classList.remove('active');
          el.classList.remove('asc');
          if (el.dataset.sort.startsWith('-')) {
            el.dataset.sort = el.dataset.sort.substr(1);
          }
        });
        element.classList.add('active');
      }
      element.dataset.sort = `${dash}${dSet}`;

      // set page to 1
      document.getElementById('page').value = 1;

      // and then call submit on the searcForm
      searchForm.dispatchEvent(submitEvent);
    });
  });
}

// if (signupForm) {
//   signupForm.addEventListener("submit", async (e) => {
//     e.preventDefault();
//     const name = document.getElementById("name").value;
//     const email = document.getElementById("email").value;
//     await signup(name, email);
//   });
// }

if (createForm) {
  createForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    let user = false;
    if (!document.getElementById('company-only').checked) {
      const name = document.getElementById('name').value;
      const email = document.getElementById('email').value;
      user = { name, email };
    }
    const company = new FormData();
    company.append('name', document.getElementById('company-name').value);
    company.append('email', document.getElementById('company-email').value);
    company.append('address', document.getElementById('company-address').value);
    company.append('zip', document.getElementById('company-zip').value);
    company.append('city', document.getElementById('company-city').value);
    if (document.getElementById('keep-recordings')) {
      const keepRecordings = document.getElementById('keep-recordings').checked;
      if (keepRecordings) {
        const recordings = JSON.stringify({
          deleteAfter: document.getElementById('delete-after').value,
          keepRecordings: keepRecordings ? 'yes' : 'no',
        });
        company.append('recordings', recordings);
      }
    }

    company.append('logo', document.getElementById('logo').files[0]);

    if (document.getElementById('pbxId')) {
      company.append('pbxId', document.getElementById('pbxId').value);
      company.append(
        'flexfoneApiKey',
        document.getElementById('flexfoneApiKey').value,
      );
    }
    await create(company, user);
  });
}

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (updateDataForm) {
  updateDataForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('name').value);
    form.append('email', document.getElementById('email').value);
    form.append('photo', document.getElementById('photo').files[0]);
    await updateSettings(form, 'brugerdata');
  });
}

if (updateCompanyDataForm) {
  updateCompanyDataForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', document.getElementById('company-name').value);
    form.append('email', document.getElementById('company-email').value);
    form.append('logo', document.getElementById('logo').files[0]);
    const keepRecordings = document.getElementById('keep-recordings').value;
    if (keepRecordings !== 'no') {
      const recordings = JSON.stringify({
        deleteAfter: document.getElementById('delete-after').value,
        keepRecordings,
      });
      form.append('recordings', recordings);
    }
    form.append(
      'expireCallsAfter',
      document.getElementById('calls-expires-after').value,
    );
    form.append(
      'jwtCookieExpiresIn',
      document.getElementById('cookie-expires-in').value,
    );
    form.append(
      'jwtExpiresIn',
      `${document.getElementById('cookie-expires-in').value}d`,
    );

    await updateSettings(form, 'firmadata');
  });
}

if (smsSettingsForm) {
  smsSettingsForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const form = new FormData();
    const sms = JSON.stringify({
      sender: document.getElementById('sender').value,
      class: document.getElementById('sms-class').value,
      deleteAfter: document.getElementById('delete-sms-after').value,
    });
    form.append('sms', sms);
    await updateSettings(form, 'firmadata');
  });
}

if (smsEditForm) {
  // tastevalg, tovejsSms, "send sms ved dette svar" og error-reply skal toggle hidden ved click
  const togglers = smsEditForm.querySelectorAll(
    "[id^='ivrchoice-'], [id^='twowaysms-'], [id^='reply-'], [id^='add-errorreply-']",
  );
  Array.from(togglers).forEach((element) => {
    element.addEventListener('click', (e) => {
      toggle(element.closest('.form__group').nextElementSibling);
    });
  });

  const addAnswerBtns = smsEditForm.querySelectorAll("[class^='add-answer-'");
  Array.from(addAnswerBtns).forEach((answerBtn) => {
    const index = +answerBtn.dataset.index + 1;
    answerBtn.addEventListener('click', async (e) => {
      e.preventDefault();
      await addAnswer(answerBtn.dataset.smsid, index);
    });
  });
  smsEditForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    const id = e.submitter.dataset.id;
    const parent = document.getElementById(`parent-${id}`).value;
    let sender = document.querySelector("[id^='sender-']").value;
    if (sender == 'alpha') {
      sender = { alphaOrShort: 'alpha' };
    } else {
      sender = { alphaOrShort: 'short', index: sender };
    }
    console.log(id, parent, sender);
    await saveSmses(id, sender, parent);
  });
}

if (userPasswordForm) {
  userPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';

    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password',
    );
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
    document.querySelector('.btn--save-password').textContent = 'Save password';
  });
}

if (resetPasswordForm) {
  resetPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Opdaterer...';

    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    const token = document.getElementById('token').value;
    await resetPassword({ password, passwordConfirm }, token);
  });
}

if (forgotPasswordForm) {
  forgotPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();

    const email = document.getElementById('email').value;
    await forgotPassword(email);
  });
}

if (syncMyfoneUsersBtn) {
  syncMyfoneUsersBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    await syncMyfoneUsers();
  });
}

if (salesforceInstallBtn) {
  salesforceInstallBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/salesforce/install');
  });
}

if (salesforceUninstallBtn) {
  salesforceUninstallBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/salesforce/uninstall');
  });
}

if (hubspotInstallBtn) {
  hubspotInstallBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/hubspot/install');
  });
}

if (syncHubspotUsersBtn) {
  syncHubspotUsersBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/hubspot/sync/owners');
  });
}

if (syncHubspotBtn) {
  syncHubspotBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/hubspot/sync');
  });
}

if (hubspotUninstallBtn) {
  hubspotUninstallBtn.addEventListener('click', async (e) => {
    e.preventDefault();
    location.assign('/hubspot/uninstall');
  });
}
