import axios from 'axios';
import { showAlert, toggleOpen } from './utils';
import { data } from 'autoprefixer';

export const downloadRecording = async (id, name) => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/api/v1/recordings/${id}`,
      responseType: 'blob', // important
    });
    //console.log(res);
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    //console.log(err);
    showAlert('error', err.response?.data?.message);
  }
};

export const deleteRecording = async (id, el) => {
  try {
    const res = await axios({
      method: 'DELETE',
      url: `/api/v1/recordings/${id}`,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Optagelsen er slettet.');
      window.setTimeout((el) => {
        toggleOpen(el);
      }, 5000);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const transcribeRecording = async (id, el, options = null) => {
  try {
    showAlert('success', 'Optagelsen transkriberes ...');
    const data = { transcribe: true };
    if (options) {
      data.options = options;
    }
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/recordings/${id}`,
      data,
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Optagelsen er transskriberet.');
      console.log('res.data', res.data);

      el.querySelector('.card__footer-text').textContent =
        res.data.data.data.transcription.text;
      el.querySelector('.card__footer-text').setAttribute(
        'id',
        `transcription-text-${id}`,
      );
      el.querySelector('.btn--download-transcription').classList.remove(
        'hidden',
      );
    } else {
      showAlert('error', res.data.message);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const syncHubspotUser = async (id, hubspotOwnerId) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${id}`,
      data: { hubspotOwnerId },
    });
    if (res.data.status === 'success') {
      showAlert('success', 'Brugeren blev synkroniseret med Hubspot.');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const getDBandMyfoneUsers = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: `/api/v1/users/dbandmyfone`,
    });
    return res.data;
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createUser = async (user) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `/api/v1/users/`,
      data: { ...user, myfoneSync: true },
    });
    if (res.data.status === 'success') {
      showAlert('success', `${user.name} blev oprettet.`);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateUser = async (filter, id) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/${id}`,
      data: filter,
    });
    if (res.data.status === 'success') {
      showAlert('success', `${filter.name} blev opdateret.`);
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const createSms = async (data, showSuccess = false) => {
  try {
    console.log('createSms sending...', data);

    const res = await axios({
      method: 'POST',
      url: `/api/v1/smsMessage/`,
      data,
    });
    console.log('createSms, created:', res.data);
    if (res.data.status === 'succes') {
      if (showSuccess) showAlert('success', `SMS'en blev oprettet.`);
      return res.data.data.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    return { data: null };
  }
};

export const updateSms = async (data, id, showSuccess = false) => {
  try {
    console.log('updateSms saving...', data, id);
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/smsMessage/${id}`,
      data,
    });
    console.log('updateSms, saved:', res.data);
    if (res.data.status === 'success') {
      if (showSuccess) showAlert('success', `SMS'en blev gemt.`);
      return true;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    return false;
  }
};
