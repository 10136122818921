/* eslint-disable */
import axios from 'axios';
import Litepicker from 'litepicker';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { saveAs } from 'file-saver';
import {
  createUser,
  updateUser,
  deleteRecording,
  downloadRecording,
  transcribeRecording,
  getDBandMyfoneUsers,
  syncHubspotUser,
  createSms,
  updateSms,
} from './controllers';
import { checkBoxSettings } from './updateSettings';
import { callCard } from './templates';

// for use with dispatchEvent
export const submitEvent = new Event('submit', {
  cancelable: true, // see https://stackoverflow.com/questions/49587933/firefox-doesnt-preventing-dispatched-submit-event
});

export const hideAlert = () => {
  const el = document.querySelector('.alert');
  if (el) {
    el.parentElement.removeChild(el);
  }
};

export const redirect = (url, delay = 0) => {
  window.setTimeout(() => {
    window.location.assign(url);
  }, delay);
};

const saveDocumentToFile = (doc, name) => {
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${name}.docx`);
  });
};

const downloadDocx = async (id, name) => {
  const text = document.getElementById(id).innerHTML;
  // find all newlines, and split the text into an array
  // then create a new paragraph for each line
  const textArray = text.split(/\r?\n/);
  const paragraphs = [];
  textArray.forEach((line) => {
    paragraphs.push(new Paragraph({ children: [new TextRun(line)] }));
  });
  const doc = new Document({
    sections: [
      {
        properties: {},
        children: paragraphs,
      },
    ],
  });
  // doc.createParagraph(document.getElementById(id).innerHTML);
  saveDocumentToFile(doc, name);
};

// type is success or error
export const showAlert = (type, msg) => {
  hideAlert();
  const markup = `<div class="alert alert--${type}">${msg}</div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, 5000);
};

export const toggle = (element) => {
  let hidden = element.classList.contains('hidden');
  if (hidden) {
    element.classList.remove('hidden');
  } else {
    element.classList.add('hidden');
  }
};

export const toggleSort = (el) => {
  if (el);
};

export const toggleOpen = (wrapper, content) => {
  let open = wrapper.classList.contains('open');
  if (open) {
    wrapper.style.height = '0px';
    wrapper.classList.remove('open');
  } else {
    const height = content.getBoundingClientRect().height;
    wrapper.style.height = `${height}px`;
    wrapper.classList.add('open');
  }
};

// https://stackoverflow.com/questions/494143/creating-a-new-dom-element-from-an-html-string-using-built-in-dom-methods-or-pro/35385518#35385518
function htmlToElement(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

const timeConverter = (duration) => {
  if (duration) {
    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration - hours * 3600) / 60);
    let seconds = duration - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0${hours}`;
    } else {
      hours = `${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${hours}:${minutes}:${seconds}`;
  }
  return '';
};

export const initiateCard = (card) => {
  // hide footer
  card.querySelector('.card__footer--wrapper').style.height = '0px';
  if (!card.classList.contains('noAppMatch')) {
    const durationCard = card.querySelector('.card__data.duration');
    const deleteBtn = card.querySelector('a.btn--delete');
    const downloadBtn = card.querySelector('a.btn--download');
    const infoBtn = card.querySelector('a.btn--info');
    const transcribeBtn = card.querySelector('a.btn--transcribe');
    const transcribeWithOptionsBtn = card.querySelector(
      'a.btn--transcribe-with-options',
    );
    const downloadTranscriptionBtn = card.querySelector(
      'a.btn--download-transcription',
    );

    ////// Info Button
    // change duration to hours:minutes:seconds
    if (durationCard) {
      durationCard.querySelector('span').innerHTML = timeConverter(
        durationCard.dataset.duration,
      );
    }
    // attach click eventlistener
    if (infoBtn) {
      infoBtn.addEventListener('click', () => {
        toggleOpen(
          card.querySelector('.card__footer--wrapper'),
          card.querySelector('.card__footer'),
        );
      });
    }

    if (downloadBtn) {
      downloadBtn.addEventListener('click', () => {
        downloadRecording(downloadBtn.dataset.id, downloadBtn.dataset.name);
      });
    }

    if (deleteBtn) {
      deleteBtn.addEventListener('click', () => {
        if (
          confirm(
            `Er du sikker på at du vil slette optagelsen ${deleteBtn.dataset.id}`,
          )
        ) {
          deleteRecording(deleteBtn.dataset.id, card);
        }
      });
    }
    if (transcribeBtn) {
      transcribeBtn.addEventListener('click', async () => {
        transcribeRecording(transcribeBtn.dataset.id, card);
      });
    }
    if (transcribeWithOptionsBtn) {
      transcribeWithOptionsBtn.addEventListener('click', async () => {
        const language = document.getElementById(
          `transcribe-language-${transcribeWithOptionsBtn.dataset.id}`,
        ).value;
        transcribeRecording(transcribeWithOptionsBtn.dataset.id, card, {
          language,
        });
      });
    }
    if (downloadTranscriptionBtn) {
      downloadTranscriptionBtn.addEventListener('click', async () => {
        downloadDocx(
          `transcription-text-${downloadTranscriptionBtn.dataset.id}`,
          downloadTranscriptionBtn.dataset.name,
        );
      });
    }
    // add clickevents for checkboxes
    const usersOrCompanies = document.querySelector('.card-container.users')
      ? 'users'
      : document.querySelector('.card-container.companies')
        ? 'companies'
        : false;
    if (usersOrCompanies) {
      const checkBoxes = card.querySelectorAll('.checkbox input');
      Array.from(checkBoxes).forEach((checkBox) => {
        checkBox.addEventListener('click', async (e) => {
          let data = {};
          let value = checkBox.checked;
          if (checkBox.value == 'role') value = value ? 'admin' : 'user';
          data[checkBox.value] = value;
          checkBoxSettings(usersOrCompanies, data, checkBox.dataset.id);
        });
      });
      // if there's a select element for transcribe-recordings, add eventlistener for when a new value is selected, and save the new value to the database
      const transcribeRecordings = card.querySelector('.transcribe-recordings');
      if (transcribeRecordings) {
        transcribeRecordings.addEventListener('change', async (e) => {
          let data = {};
          data.transcribeRecordings = transcribeRecordings.value;
          updateUser(data, transcribeRecordings.dataset.id);
        });
      }
    }
  }
  // if we are on user administration page, check if are syncing from myfone
  const classList = [
    'fullMatch',
    'partialMatch',
    'emailMatch',
    'noAppMatch',
    'noMyfoneMatch',
    'noMatch',
    'noMyfoneHubspotMatch',
  ];
  classList.some((className) => {
    //console.log("hello classlist", className);
    if (card.classList.contains(className)) {
      //console.log("hello if", className);

      syncready(card, className);
    }
  });
};

//- https://stackoverflow.com/questions/9899372/pure-javascript-equivalent-of-jquerys-ready-how-to-call-a-function-when-t/9899701#9899701
export const docReady = (fn) => {
  // see if DOM is already available
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
};

// delay is used by keyup event below so we don't search before finished typing
function delay(fn, ms) {
  let timer = 0;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(fn.bind(this, ...args), ms || 0);
  };
}

export let litePicker = undefined;

export const litePickerInit = (
  element,
  phoneNumber,
  localNumber,
  searchForm,
) => {
  litePicker = new Litepicker({
    element,
    lang: 'da-DK',
    singleMode: false,
    showWeekNumbers: true,
    firstDay: 1,
    resetButton: () => {
      let btn = document.createElement('button');
      btn.innerText = 'Clear';
      btn.addEventListener('click', (evt) => {
        evt.preventDefault();

        // set page to 1
        document.getElementById('page').value = 1;
        //search
        searchForm.dispatchEvent(submitEvent);
        litePicker.hide();
      });

      return btn;
    },
  }).on('selected', () => {
    // set page to 1
    document.getElementById('page').value = 1;
    //search
    searchForm.dispatchEvent(submitEvent);
  });

  phoneNumber.addEventListener(
    'keyup',
    delay((e) => {
      // set page to 1
      document.getElementById('page').value = 1;
      // search
      searchForm.dispatchEvent(submitEvent);
    }, 1500),
  );
  localNumber.addEventListener(
    'keyup',
    delay((e) => {
      if (e.target.value.length >= 3 || e.target.value.length === 0) {
        // set page to 1
        document.getElementById('page').value = 1;
        // search
        searchForm.dispatchEvent(submitEvent);
      }
    }, 1500),
  );
};

export const search = async (
  phoneNumber,
  localNumber,
  startDate,
  endDate,
  page = 1,
) => {
  console.log(startDate, endDate);
  let params = {};
  if (startDate) {
    const startDateTime = new Date(startDate.dateInstance);
    const endDateTime = new Date(endDate.dateInstance);

    // Adjusting endDateTime to include the whole day
    endDateTime.setHours(endDateTime.getHours() + 24);

    params['date[gte]'] = startDateTime;
    params['date[lte]'] = endDateTime;
  }
  if (phoneNumber) {
    params['phoneNumber[regex]'] = phoneNumber;
  }

  if (localNumber) {
    params.localNumber = localNumber;
  }

  // get sort params
  const sortElements = document.querySelectorAll('.header--sort .active');
  //console.log(sortElements);
  let sortArray = Array.from(sortElements).map((el) => {
    return el.dataset.sort;
  });
  //console.log(sortArray);
  params.sort = sortArray.toString();
  let url = '/api/v1/recordings';
  if (document.getElementById('own-recordings')) {
    const userId = document.getElementById('own-recordings').value;
    url = `/api/v1/users/${userId}/recordings`;
  }

  // paging
  if (page > 1) params.page = page;

  try {
    const res = await axios.get(url, { params });
    console.log(res);
    if (res.data.status === 'success') {
      return res.data;
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

export const updateView = (template, docs) => {
  const recordings = document.querySelector('.card-container.recordings');
  const page = document.getElementById('page').value;
  document.getElementById('recordings-footer').innerHTML = '';

  if (docs.results > 0) {
    if (page == 1) recordings.innerHTML = '';

    docs.data.data.forEach((element) => {
      recordings.appendChild(callCard(element));
      toggleOpen(
        recordings.lastChild,
        recordings.lastChild.querySelector('.card'),
      );
      initiateCard(recordings.lastChild);
    });
  } else {
    if (page > 1) document.getElementById('page').value--; // we are still at same page, nothing more/new was found
    if (page == 1) {
      recordings.innerHTML =
        '<p>Vi fandt ingen optagelser med de givne søgekriterier.</p>';
    } else {
      // add message about no more results to footer

      const message = '<p>Så kunne vi ikke finde flere optagelser :)</p>';
      document.getElementById('recordings-footer').innerHTML = message;
    }
  }
  console.log('view updated');
};

export const toggleSpinner = (state) => {
  const spinner = document.querySelector('.spinner');
  if (state == 'on' && !spinner.classList.contains('active')) {
    spinner.classList.add('active');
  }
  if (state == 'off') {
    spinner.classList.remove('active');
  }
};

export const initiateObserver = (element, searchForm) => {
  const intersectionObserver = new IntersectionObserver(async (entries) => {
    for (let index = 0; index < entries.length; index++) {
      const entry = entries[index];
      if (entry.isIntersecting && !entry.target.classList.contains('visible')) {
        // count page one up
        //console.log("loading more..");
        document.getElementById('page').value++;
        // add class visible
        entry.target.classList.add('visible');
        // search - the submit eventhandler detects through page > 1 that it should load the next 100 conversations
        await searchForm.dispatchEvent(submitEvent);
      } else {
        entry.target.classList.remove('visible');
      }
    }
  });
  // start observing
  intersectionObserver.observe(element);
};

export const syncMyfoneUsers = async () => {
  const users = htmlToElement(await getDBandMyfoneUsers());
  let container = document.querySelector('.card-container.users');
  container.replaceWith(users);
  users.querySelectorAll('.card').forEach((card) => {
    initiateCard(card);
  });
};

export const saveSmses = async (id, sender, parent = null) => {
  const sms = {
    text: document.getElementById(`smstext-${id}`).value,
    sender,
  };
  // check if this is the top most parent sms (parent = null)
  if (parent == 0 || parent == null) {
    // set name
    sms.name = document.getElementById(`name-${id}`).value;
  }
  // if id is a valid timestamp, it's a new sms, if not we have the sms (object)id
  let isNew = false;
  if (id == 'newSms') {
    isNew = true;
    const createdSms = await createSms(sms);
    console.log(createdSms);
    id = createdSms.id;
  }

  // check for minDuration
  if (document.getElementById(`minduration-${id}`)) {
    sms.minDuration = document.getElementById(`minduration-${id}`).value;
  }

  // check for lostCallSms
  if (document.querySelector("[id^='lostcallsms-']")) {
    sms.lostCallSms = {
      active: document.querySelector("[id^='lostcallsms-']").checked,
    };
  }

  // check for ivRChoice
  if (document.querySelector("[id^='ivrchoice-']")) {
    sms.ivrChoice = {
      active: document.querySelector("[id^='ivrchoice-']").checked,
    };
    if (sms.ivrChoice.active) {
      sms.ivrChoice.digit = document.getElementById(`digit-${id}`).value;
      sms.ivrChoice.sendSmsIfDigit = document.getElementById(
        `sendsmsifdigit-${id}`,
      ).checked;
    }
  }

  // twoWaySms
  if (document.getElementById(`twowaysms-${id}`)) {
    sms.twoWaySms = {
      active: document.getElementById(`twowaysms-${id}`).checked,
      answers: [],
      replies: [],
    };
    // check for errorReply
    if (document.getElementById(`add-errorreply-${id}`)) {
      sms.twoWaySms.errorReply = {
        active: document.getElementById(`add-errorreply-${id}`).checked,
      };
      if (sms.twoWaySms.errorReply.active) {
        const errorReplyTextElement = document.getElementById(
          `errorreplytext-${id}`,
        );
        const errorReplySms = {
          text: errorReplyTextElement.value,
          sender,
          parent: { message: id, replyToParent: true },
        };
        let errorReplyId;
        if (errorReplyTextElement.dataset.errorreplyid) {
          updateSms(errorReplySms, errorReplyTextElement.dataset.errorreplyid);
          errorReplyId = errorReplyTextElement.dataset.errorreplyid;
        } else {
          const createdErrorReply = await createSms(errorReplySms);
          errorReplyId = createdErrorReply.data.id;
        }
        sms.twoWaySms.errorReply.message = errorReplyId;
      }
    }
    const answers = Array.from(
      document.querySelectorAll(`[id^="answer-${id}-"]`),
    );
    for (const answer of answers) {
      const i = answer.id.split('-')[2];
      console.log('i:', i);
      sms.twoWaySms.answers[i] = answer.value;
      // check if reply is ticked and if there is a new or excisting message
      if (document.getElementById(`reply-${id}-${i}`)) {
        sms.twoWaySms.replies[i] = {
          active: document.getElementById(`reply-${id}-${i}`).checked,
        };
        if (sms.twoWaySms.replies[i].active) {
          if (document.getElementById(`newreply-${id}-${i}`)) {
            const newSms = {
              text: document.getElementById(`newreply-${id}-${i}`).value,
              sender,
              parent: {
                message: id,
              },
            };
            const createdReply = await createSms(newSms);
            sms.twoWaySms.replies[i].message = createdReply.data.id;
          } else {
            const replyId = document
              .getElementById(`reply-${id}-${i}`)
              .closest('.form__group')
              .nextElementSibling.firstChild.id.split('-')[2];
            await saveSmses(replyId, sender, id);
            sms.twoWaySms.replies[i].message = replyId;
          }
        }
      }
    }
  }
  await updateSms(sms, id);
  if (parent == 0 || parent == null) {
    let message = 'SMS opdateret';
    if (isNew) {
      message = 'SMS oprettet';
      showAlert('success', message);
      redirect(`/sms/${id}`, 3000);
    }
    showAlert('success', message);
  }
};

// function to get the selected value from a select element
export const getSelectedValue = (selectElement) => {
  const selectedOption = selectElement.options[selectElement.selectedIndex];
  const selectedValue = selectedOption.value;
  return selectedValue;
};

const syncready = async (card, className) => {
  switch (className) {
    case 'partialMatch':
      // email and/or name not matching - either it's same user, or new user overtaking same localNumber
      const updateOrNew = card.querySelector('.update-user');
      updateOrNew.addEventListener('change', async (e) => {
        e.preventDefault();
        const user = JSON.parse(updateOrNew.dataset.user);
        await updateOrCreateUser(user, updateOrNew.value);
      });
      break;
    case 'emailMatch':
      // an admin with login is not necesarily a myfoneuser from the start - in this case a matching myfoneuser is found
      break;
    case 'noAppMatch':
      // this is a new myfoneuser, not yet in the app
      const createBtn = card.querySelector('.btn--create');
      createBtn.addEventListener('click', async (e) => {
        e.preventDefault();
        const user = JSON.parse(createBtn.dataset.user);
        const settings = {
          role: document.getElementById(`role-${user.id}`).checked
            ? 'admin'
            : 'user',
          active: document.getElementById(`active-${user.id}`).checked,
        };
        if (document.getElementById(`keep-recordings-${user.id}`)) {
          settings.keepRecordings = document.getElementById(
            `keep-recordings-${user.id}`,
          ).checked;
        }
        await createNewUser(user, settings);
        card.lastChild.remove;
      });
      break;
    case 'noMyfoneMatch':
      // this is an appuser no longer having a matching myfoneuser
      const deleteUserBtn = card.querySelector('.btn--delete-user');
      deleteUserBtn.addEventListener('click', async (e) => {
        e.preventDefault();
        const keepRecordings = card.querySelector('.keep-recordings')
          ? card.querySelector('.keep-recordings').value
          : false;
        const user = JSON.parse(deleteUserBtn.dataset.user);
        deleteUser(user, keepRecordings);
      });
      break;
    case 'noMyfoneHubspotMatch':
      // this is an appuser no longer having a matching myfoneuser
      console.log('hello');
      const syncUserBtn = card.querySelector('.btn--sync-user');
      syncUserBtn.addEventListener('click', async (e) => {
        e.preventDefault();
        const selectedOwner = card.querySelector('.sync-hubspot-user').value;
        const user = syncUserBtn.dataset.user;
        syncHubspotUser(user, selectedOwner);
      });
      break;
    default:
      // fullMatch and noMatch, move to bottom of userlist so we list changed/new first
      card.parentElement.parentElement.append(card.parentElement);
      break;
  }
};

export const addAnswer = async (id, index) => {
  try {
    const params = { id, index };
    const res = await axios.get('/api/v1/smsMessage/getanswerform', { params });
    console.log('addAnswer response: ', res);
    if (res.status === 200) {
      const oldAnswerBtn = document.querySelector(
        `.add-answer-${id}-${index - 1}`,
      );
      oldAnswerBtn.insertAdjacentHTML('afterend', res.data);
      oldAnswerBtn.remove();
    }
  } catch (err) {
    console.log(err);
    showAlert('error', err.response.data.message);
  }
};

const createNewUser = async (user, settings) => {
  console.log(user);
  console.log(settings);
  const userSettings = {
    ...settings,
    localNumber: user.localNumber,
    email: user.email,
    name: user.name,
    phones: user.phones,
    department: { name: user['department.name'] },
  };
  await createUser(userSettings);
};

const updateOrCreateUser = async (user, newOrUpdate) => {
  console.log(user);
  console.log(newOrUpdate);
  if (newOrUpdate == 'update-user') {
    const filter = { email: user.mfEmail, name: user.mfName };
    await updateUser(filter, user.id);
  } else {
    showAlert('error', `Denne feature kommer snart...`);
  }
};

const deleteUser = (user, keepRecordings) => {
  console.log(user);
  console.log(keepRecordings);
  showAlert(
    'error',
    `Denne feature kommer snart...<br/>Ingen bruger blev slettet.`,
  );
};
