/* eslint-disable */

import axios from "axios";
import { showAlert } from "./utils";
// type is either password or data
export const updateSettings = async (data, type) => {
  try {
    let url;
    switch (type) {
      case "brugerdata":
        url = "/api/v1/users/updateMe";
        break;
      case "password":
        url = "/api/v1/users/updateMyPassword";
        break;
      case "firmadata":
        url = "/api/v1/companies/updateMyCompany";
        break;
      default:
        break;
    }
    const res = await axios({
      method: "PATCH",
      url,
      data,
    });

    if (res.data.status === "success") {
      showAlert("success", `Updaterede ${type.toUpperCase()}`);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const checkBoxSettings = async (path, data, id) => {
  try {
    if (data.sms) {
      data.smsApiToken = prompt(
        "Copy-paste API-token til sms gatewayen her. Kontakt Teleplan hvis du ikk har fået en API-token til sms gatewayen"
      );
    }
    const url = `/api/v1/${path}/${id}`;
    const res = await axios({
      method: "PATCH",
      url,
      data,
    });

    if (res.data.status === "success") {
      const updated = path == "users" ? "bruger." : "firma.";
      showAlert("success", `Opdaterede ${updated}`);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const resetPassword = async (data, token) => {
  try {
    const url = `/api/v1/users/resetPassword/${token}`;
    const res = await axios({
      method: "PATCH",
      url,
      data,
    });

    if (res.data.status === "success") {
      showAlert("success", `Password opdateret`);
    }
    window.setTimeout(() => {
      location.assign("/");
    }, 2000);
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};
