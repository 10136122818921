import moment from "moment";
export const callCard = (recording) => {
  const div = document.createElement("DIV");
  let localNumber;
  if (recording.user && recording.user.name) {
    localNumber = `<div class="card__data name" title="Navn"><span class="user__photo-and-name"><img class="user__photo" src="/img/users/${
      recording.user.photo ? recording.user.photo : "default.jpg"
    }" alt="foto"><div class="user__name">${
      recording.user.name
    }</div></span></div><div class="card__data localNumber" title="Lokalnummer" data-sort="localNumber"><span>${
      recording.localNumber
    }</span></div>`;
  } else {
    localNumber = `<div class='card__data local-number'>\
                 <svg class='card__icon'>\
                   <use xlink:href='/img/icons.svg#icon-user'></use>\
                 </svg>\
                 <span>${recording.localNumber}</span>\
               </div>`;
  }
  div.innerHTML = `<div class='card--wrapper'>\
           <div class='card'>\
             <div class='card__details'>\
               <div class='card__data date'>\
                 <svg class='card__icon'>\
                   <use xlink:href='/img/icons.svg#icon-calendar'></use>\
                 </svg>\
                 <span>${moment(recording.date).format(
                   "DD/MM-YYYY HH:mm"
                 )}</span>\
               </div>\
               <div\
                 class='card__data duration'\
                 title='Optagelsens længde i timer:minutter:sekunder'\
                 data-duration='${recording.duration}'\
               >\
                 <svg class='card__icon'>\
                   <use xlink:href='/img/icons.svg#icon-watch'></use>\
                 </svg>\
                 <span>${recording.duration ? recording.duration : "-"}</span>\
               </div>\
               <div class='card__data external-phone-number'>\
                 <svg class='card__icon'>\
                   <use xlink:href='/img/icons.svg#icon-phone-${
                     recording.direction
                   }'></use>\
                 </svg>\
                 <span>${recording.phoneNumberExternal}</span>\
               </div>\
               ${localNumber}\
               <div class='card__data buttons'>\
                 <a\
                   class='btn btn--red btn--small tiny btn--delete'\
                   href='#delete'\
                   data-id='${recording.id}'\
                   title='Slet optagelsen permanent!'\
                 >\
                   <svg class='card__icon'>\
                     <use xlink:href='/img/icons.svg#icon-x-circle'></use>\
                   </svg>\
                 </a>\
                 <a\
                   class='btn btn--green btn--small tiny btn--download'\
                   href='#download'\
                   data-name='${recording.name}'\
                   data-id='${recording.id}'\
                   title='Download optagelsen'\
                 >\
                   <svg class='card__icon'>\
                     <use xlink:href='/img/icons.svg#icon-download'></use>\
                   </svg>\
                 </a>\
                 <a\
                   class='btn btn--green btn--small tiny btn--info'\
                   href='#details'\
                   title='Flere detaljer om opkaldet'\
                 >\
                   <svg class='card__icon'>\
                     <use xlink:href='/img/icons.svg#icon-info'></use>\
                   </svg>\
                 </a>\
               </div>\
             </div>\
             <div class='card__footer--wrapper' style='height: 0px;'>\
               <div class='card__footer'>\
                 <p class='card__note'>\
                   <span class='card__footer-value'>Note</span>{' '}\
                   <span class='card__footer-text'>\
                     recording.note{' '}\
                     <span class='card__note__edit'>\
                       <svg class='card__icon'>\
                         <use xlink:href='/img/icons.svg#icon-edit'></use>\
                       </svg>\
                     </span>\
                   </span>\
                 </p>\
                 <p class='card__log'>\
                   <span class='card__footer-value'></span>{' '}\
                   <span class='card__footer-text'>\
                     ... her kommer log over tilgange til filen\
                     <span class='card__log__expand'>\
                       <svg class='card__icon'>\
                         <use xlink:href='/img/icons.svg#icon-chevron-down'></use>\
                       </svg>\
                     </span>\
                   </span>\
                 </p>\
               </div>\
             </div>\
           </div>\
         </div>\
       "`;
  return div.firstChild;
};

export const userCard = (users) => {
  const div = document.createElement("DIV");
  div.innerHTML = ``;
};
