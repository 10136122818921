/* eslint-disable */
import axios from "axios";
import { showAlert } from "./utils";

export const login = async (email, password) => {
  try {
    const res = await axios({
      method: "POST",
      url: "/api/v1/users/login",
      data: { email, password },
    });
    if (res.data.status === "success") {
      showAlert("success", "Logged in successfully!");
      window.setTimeout(() => {
        location.assign("/");
      }, 1500);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
    if (err.response.data.message === "Forkert email eller password") {
      document.querySelector(".forgot-password").classList.add("active");
    }
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: "GET",
      url: "/api/v1/users/logout",
    });
    if (res.data.status === "success") {
      // location.reload(true); // reload true makes shure the user get the new cookie
      location.href = "/";
    }
  } catch (err) {
    showAlert("error", "Error logging out - please try again.");
  }
};

export const forgotPassword = async (email) => {
  try {
    const res = await axios({
      method: "POST",
      url: "/api/v1/users/forgotpassword",
      data: { email },
    });

    if (res.data.status === "success") {
      console.log(res.data);
      showAlert("success", res.data.message);
      window.setTimeout(() => {
        location.assign("/");
      }, 2000);
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};

export const create = async (company, user) => {
  try {
    const resCompany = await axios({
      method: "POST",
      url: "/api/v1/companies",
      data: company,
    });

    if (resCompany.data.status === "success") {
      showAlert("success", `${resCompany.data.data.name} er oprettet.`);
      if (user) {
        try {
          user.companyId = resCompany.data.data.id;
          //console.log(user);
          const resUser = await axios({
            method: "POST",
            url: "/api/v1/users",
            data: user,
          });

          if (resUser.data.status === "success") {
            showAlert(
              "success",
              `Sådan.<br>Der er sendt instrukser per mail til ${resUser.data.data.name}`
            );
            window.setTimeout(() => {
              location.assign("/me");
            }, 2000);
          }
        } catch (err) {
          showAlert("error", err.response.data.message);
        }
      }
    }
  } catch (err) {
    showAlert("error", err.response.data.message);
  }
};
